<template>
  <div ref="root" class="wrapper">
    <transition name="fade">
      <p v-show="isVisible">{{ content }}</p>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref, watch } from "vue";
import { useIsInViewport } from "./useIsInViewport";

const randomInt = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

const showWithDelay = (isVisible: Ref<boolean>) => {
  setTimeout(() => {
    isVisible.value = true;
  }, randomInt(1000, 2500));
};

export default defineComponent({
  props: {
    content: String,
  },
  setup() {
    const root = ref<HTMLParagraphElement | undefined>(undefined);
    const isTextVisible = ref(false);

    onMounted(() => {
      if (!root.value) {
        throw new Error("Unable to find element reference");
      }

      const { isInViewport, unsubscribe } = useIsInViewport(root.value);

      if (isInViewport.value) {
        showWithDelay(isTextVisible);
        unsubscribe();
        return;
      }

      watch(
        () => isInViewport.value,
        (isInViewport) => {
          if (!isInViewport) {
            return;
          }

          showWithDelay(isTextVisible);
          unsubscribe();
        }
      );
    });

    return { root, isVisible: isTextVisible };
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 1rem;
  margin: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
