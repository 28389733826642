import { getApp, initializeApp } from "firebase/app";
import {
  connectDatabaseEmulator,
  equalTo,
  getDatabase,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  orderByChild,
  push,
  query,
  ref,
  set,
} from "firebase/database";

/**
 * @description
 * During hot reloading connectDatabaseEmulator throws an error about already emulated env
 */
const isAlreadyEmulatedEnv = (db: any): boolean => {
  return db._repoInternal.repoInfo_.internalHost.includes("localhost");
};

const initDb = (firebaseConfig: Parameters<typeof initializeApp>[0]) => {
  try {
    getApp();
  } catch (e) {
    initializeApp(firebaseConfig);
  }

  return getDatabase();
};

export const initPostsList = <
  P extends { id: string; content: string; isPublished: boolean }
>(
  firebaseConfig: Parameters<typeof initializeApp>[0],
  listName: string,
  isEnvEmulated: boolean
) => {
  const db = initDb(firebaseConfig);

  if (isEnvEmulated && !isAlreadyEmulatedEnv(db)) {
    connectDatabaseEmulator(db, "localhost", 9000);
  }

  const listRef = ref(db, listName);
  const listPublishedItemsQuery = query(
    listRef,
    orderByChild("isPublished"),
    equalTo(true)
  );

  const add = (data: Omit<P, "id" | "isPublished">) => {
    const newItem = push(listRef);

    set(newItem, {
      ...data,
      isPublished: true,
    });
  };

  const onAdd = (onAddCallback: (post: P) => void) =>
    onChildAdded(listPublishedItemsQuery, (data) =>
      onAddCallback({ id: data.key, ...data.val() })
    );

  const onChange = (onChangeCallback: (post: P) => void) =>
    onChildChanged(listPublishedItemsQuery, (data) =>
      onChangeCallback({ id: data.key, ...data.val() })
    );

  const onRemove = (onRemoveCallback: (post: P) => void) =>
    onChildRemoved(listPublishedItemsQuery, (data) =>
      onRemoveCallback({ id: data.key, ...data.val() })
    );

  return {
    add,
    onAdd,
    onChange,
    onRemove,
  };
};
