<template>
  <div class="grid">
    <div
      class="item"
      v-for="{ id, size, type, content, src } in items"
      :key="id"
      :class="size"
    >
      <ToeUiTextGridItem v-if="type === GridItemType.Text" :content="content" />

      <ToeUiImgGridItem v-if="type === GridItemType.Img" :src="src" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { GridItem, GridItemType } from "./ToeUiGrid.model";
import ToeUiTextGridItem from "./ToeUiTextGridItem.vue";
import ToeUiImgGridItem from "./ToeUiImgGridItem.vue";

export default defineComponent({
  components: {
    ToeUiTextGridItem,
    ToeUiImgGridItem,
  },
  props: {
    items: [] as PropType<GridItem[]>,
  },
  data: () => ({
    GridItemType,
  }),
});
</script>

<style lang="scss">
$width: 300px;
$height: 150px;
$gap: 0px;

@mixin cell-size($widthRatio, $heightRatio) {
  width: 100%;
  height: auto;

  grid-column-end: span 1;
  grid-row-end: span 1;

  @media screen and (min-width: 700px) {
    width: calc(#{$widthRatio} * #{$width} + #{$widthRatio - 1} * #{$gap});
    height: calc(#{$heightRatio} * #{$height} + #{$heightRatio - 1} * #{$gap});

    grid-column-end: span #{$widthRatio};
    grid-row-end: span #{$heightRatio};
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;

  justify-content: center;
  margin-top: 1rem;

  @media screen and (min-width: 700px) {
    grid-template-columns: repeat(auto-fill, $width);
    grid-auto-rows: $height;
    grid-auto-flow: dense;
    grid-gap: $gap;
  }

  .item {
    display: flex;
    align-items: center;

    min-height: $height;
    width: 100%;

    @media screen and (min-width: 700px) {
      width: $width;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.cell-1x3 {
      @include cell-size(1, 3);
    }

    &.cell-2x2 {
      @include cell-size(2, 2);
    }

    &.cell-2x3 {
      @include cell-size(2, 3);
    }

    &.cell-2x6 {
      @include cell-size(2, 6);
    }

    &.cell-3x3 {
      @include cell-size(3, 3);

      @media screen and (max-width: 1200px) {
        @include cell-size(2, 2);
      }
    }

    &.cell-3x5 {
      @include cell-size(3, 5);

      @media screen and (max-width: 1200px) {
        @include cell-size(2, 3);
      }
    }
  }
}
</style>
