import { createApp } from "vue";
import App from "./App.vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { Event, EventHint } from "@sentry/vue";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://fc3d9453642f4afc942dce3ec5e66c0c@o938819.ingest.sentry.io/5888556",
  beforeSend(
    event: Event,
    hint?: EventHint
  ): PromiseLike<Event | null> | Event | null {
    console.error(hint?.originalException || hint?.syntheticException);
    return event;
  },
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        "localhost",
        "theovervieweffect.pl",
        "the-overview-effect.web.app",
        "the-overview-effect.firebaseapp.com",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.mount("#app");
