import { initPostsList } from "@toe/firebase";
import { Post } from "@toe/post";
import { firebaseConfig } from "@/firebase/config";
import { reactive } from "vue";

export const usePosts = () => {
  const dbPosts = initPostsList<Post>(
    firebaseConfig,
    "posts",
    window.location.hostname === "localhost"
  );

  const posts = reactive<Record<Post["id"], Post>>({});

  dbPosts.onAdd((post) => (posts[post.id] = post));
  dbPosts.onChange((post) => (posts[post.id] = post));
  dbPosts.onRemove((post) => delete posts[post.id]);

  return {
    posts,
    addPost: (data: Parameters<typeof dbPosts.add>[0]) => dbPosts.add(data),
  };
};
