<template>
  <div class="header">
    <h1 class="header-text">najmniejsza rzecz, którą pamiętasz</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped lang="scss">
.header-text {
  font-family: "Jessi Neue", sans-serif;
  font-size: 2.55rem;
  font-weight: normal;
  margin: 0 0 .55rem 0;
}
</style>
