export enum GridItemType {
  Img = "img",
  Text = "text",
  Empty = "empty",
}

export enum GridItemSize {
  Size1x1 = "cell-1x1",
  Size1x3 = "cell-1x3",
  Size2x2 = "cell-2x2",
  Size2x3 = "cell-2x3",
  Size2x6 = "cell-2x6",
  Size3x3 = "cell-3x3",
  Size3x5 = "cell-3x5",
}

export interface GridItemBase {
  id: string;
  type: GridItemType;
  size: GridItemSize;
}

export interface EmptyGridItem extends GridItemBase {
  type: GridItemType.Empty;
}

export interface TextGridItem extends GridItemBase {
  type: GridItemType.Text;
  content: string;
}

export interface ImgGridItem extends GridItemBase {
  type: GridItemType.Img;
  src: string;
}

export type GridItem = EmptyGridItem | TextGridItem | ImgGridItem;
