<template>
  <aside class="side-info" :style="{ width: height + 'px' }">
    <p>Projekt finansowany ze Stypendium Twórczego Miasta Krakowa</p>
    <p>The overview effect, Antonina Chmielewska-Merynda</p>
  </aside>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    height: Number,
  },
});
</script>

<style lang="scss" scoped>
.side-info {
  position: absolute;
  display: flex;
  justify-content: space-around;
  transform-origin: 100% 100%;
  /* To be "stick to top we need to move up to element height because of transform-origin */
  top: -50px;
  transform: rotate(270deg);
  width: 200vh;
  max-width: 200vh;
  right: 0;
  margin: 0;
}
</style>
