export const firebaseConfig = {
  apiKey: "AIzaSyBdto72jz5AJlRpXlGoXEuYLHbPO0qBBs8",
  authDomain: "the-overview-effect.firebaseapp.com",
  databaseURL:
    "https://the-overview-effect-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "the-overview-effect",
  storageBucket: "the-overview-effect.appspot.com",
  messagingSenderId: "974627182797",
  appId: "1:974627182797:web:8348113540b76a26473184",
};
