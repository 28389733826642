import { ref } from "vue";

const isMobileView = () => window.innerWidth < 700;

export const useIsMobile = () => {
  const isMobile = ref(isMobileView());

  const setIsMobile = () => (isMobile.value = isMobileView());

  window.addEventListener("resize", setIsMobile);

  const unsubscribe = () => {
    window.removeEventListener("resize", setIsMobile);
  };

  return {
    isMobile,
    unsubscribe,
  };
};
