
import { defineComponent, ref } from "vue";
import { ToeUiHeader, ToeUiInput } from "@toe/ui";
import { usePosts } from "@/ts/usePosts";
import { initAnalytics } from "@toe/firebase";
import { firebaseConfig } from "@/firebase/config";
import ToeUiGrid from "@toe/ui/src/toe-ui-grid/ToeUiGrid.vue";
import { useGridDataGenerator } from "@/ts/useGridDataGenerator";
import { imageGridItems } from "@/ts/imageGridItems.data";
import ToeFooter from "@/components/ToeFooter.vue";
import ToeSideInfo from "@/components/ToeSideInfo.vue";
import { useIsMobile } from "@/ts/useIsMobile";
import "mosha-vue-toastify/dist/style.css";
import { createToast } from "mosha-vue-toastify";
import { useHeight } from "@/ts/useHeight";

const showSuccessToast = () =>
  createToast(
    {
      title: "Twoje wspomnienie zostało zapisane.",
      description: "Dołączyło do archiwum “The overview effect”.",
    },
    {
      hideProgressBar: false,
      toastBackgroundColor: '#cfcfcf',
      showIcon: true,
      type: "success",
      timeout: 10_000,
    }
  );

export default defineComponent({
  components: {
    ToeSideInfo,
    ToeUiInput,
    ToeUiHeader,
    ToeUiGrid,
    ToeFooter,
  },
  setup() {
    initAnalytics(firebaseConfig);
    const { posts, addPost } = usePosts();
    const { isMobile } = useIsMobile();
    const gridItems = useGridDataGenerator(posts, imageGridItems, isMobile);
    const app = ref<HTMLDivElement | null>(null);
    const { height } = useHeight(app);

    const onInputSubmit = (content: string) => {
      addPost({ content });
      showSuccessToast();
    };

    return { onInputSubmit, gridItems, app, height };
  },
});
