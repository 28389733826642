import { onMounted, ref } from "vue";
import { Ref } from "@vue/reactivity";
import ResizeObserver from "resize-observer-polyfill";

export const useHeight = (el: Ref<Element | null>) => {
  const height = ref(0);

  const resizeObserver = new ResizeObserver((entries) => {
    height.value = entries[0].contentRect.height;
  });

  onMounted(() => {
    if (!el.value) {
      return;
    }

    resizeObserver.observe(el.value);
  });

  return { height };
};
