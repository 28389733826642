
import { defineComponent, PropType } from "vue";
import { GridItem, GridItemType } from "./ToeUiGrid.model";
import ToeUiTextGridItem from "./ToeUiTextGridItem.vue";
import ToeUiImgGridItem from "./ToeUiImgGridItem.vue";

export default defineComponent({
  components: {
    ToeUiTextGridItem,
    ToeUiImgGridItem,
  },
  props: {
    items: [] as PropType<GridItem[]>,
  },
  data: () => ({
    GridItemType,
  }),
});
