import { GridItemSize, GridItemType, ImgGridItem } from "@toe/ui";

export const imageGridItems: ImgGridItem[] = [
  {
    id: "img-1",
    type: GridItemType.Img,
    src: require("./../assets/inspirations/1.jpg"),
    size: GridItemSize.Size2x6,
  },
  {
    id: "img-2",
    type: GridItemType.Img,
    src: require("./../assets/inspirations/2.jpg"),
    size: GridItemSize.Size3x3,
  },
  {
    id: "img-3",
    type: GridItemType.Img,
    src: require("./../assets/inspirations/3.jpg"),
    size: GridItemSize.Size2x3,
  },
  {
    id: "img-4",
    type: GridItemType.Img,
    src: require("./../assets/inspirations/4.jpg"),
    size: GridItemSize.Size2x3,
  },
  {
    id: "img-5",
    type: GridItemType.Img,
    src: require("./../assets/inspirations/5.jpg"),
    size: GridItemSize.Size3x3,
  },
  {
    id: "img-6",
    type: GridItemType.Img,
    src: require("./../assets/inspirations/6.jpg"),
    size: GridItemSize.Size2x3,
  },
  {
    id: "img-7",
    type: GridItemType.Img,
    src: require("./../assets/inspirations/7.jpg"),
    size: GridItemSize.Size2x3,
  },
  {
    id: "img-8",
    type: GridItemType.Img,
    src: require("./../assets/inspirations/8.jpg"),
    size: GridItemSize.Size2x3,
  },
  {
    id: "img-9",
    type: GridItemType.Img,
    src: require("./../assets/inspirations/9.jpg"),
    size: GridItemSize.Size2x3,
  },
  {
    id: "img-10",
    type: GridItemType.Img,
    src: require("./../assets/inspirations/10.jpg"),
    size: GridItemSize.Size3x3,
  },
];
