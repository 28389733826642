<template>
  <footer>
    <div class="text">
      <p>The overview effect, Antonina Chmielewska-Merynda</p>
      <p>projekt finansowany ze Stypendium Twórczego Miasta Krakowa, 2021</p>
    </div>

    <img
      class="logo"
      alt="Logo Miasta Krakowa"
      src="@/assets/LogoMiastoKrakow.jpg"
    />
  </footer>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
footer {
  font-family: "Jessi Neue", sans-serif;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  @media screen and (min-width: 700px) {
    flex-direction: row;
    align-items: stretch;
    text-align: left;
  }

  .text {
    display: flex;
    justify-content: center;
    flex-direction: column;

    font-size: 1.55rem;
    font-weight: 100;

    padding: 0 1rem 1rem 0;

    p {
      margin: 0;
    }
  }

  img {
    height: 60px;
  }
}
</style>
