<template>
  <div ref="app" class="app">
    <ToeUiHeader />
    <ToeUiInput @toe-submit="onInputSubmit" />
    <ToeUiGrid class="grid" :items="gridItems" />
    <ToeFooter class="footer" />
  </div>
  <ToeSideInfo class="side-info" :height="height" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ToeUiHeader, ToeUiInput } from "@toe/ui";
import { usePosts } from "@/ts/usePosts";
import { initAnalytics } from "@toe/firebase";
import { firebaseConfig } from "@/firebase/config";
import ToeUiGrid from "@toe/ui/src/toe-ui-grid/ToeUiGrid.vue";
import { useGridDataGenerator } from "@/ts/useGridDataGenerator";
import { imageGridItems } from "@/ts/imageGridItems.data";
import ToeFooter from "@/components/ToeFooter.vue";
import ToeSideInfo from "@/components/ToeSideInfo.vue";
import { useIsMobile } from "@/ts/useIsMobile";
import "mosha-vue-toastify/dist/style.css";
import { createToast } from "mosha-vue-toastify";
import { useHeight } from "@/ts/useHeight";

const showSuccessToast = () =>
  createToast(
    {
      title: "Twoje wspomnienie zostało zapisane.",
      description: "Dołączyło do archiwum “The overview effect”.",
    },
    {
      hideProgressBar: false,
      toastBackgroundColor: '#cfcfcf',
      showIcon: true,
      type: "success",
      timeout: 10_000,
    }
  );

export default defineComponent({
  components: {
    ToeSideInfo,
    ToeUiInput,
    ToeUiHeader,
    ToeUiGrid,
    ToeFooter,
  },
  setup() {
    initAnalytics(firebaseConfig);
    const { posts, addPost } = usePosts();
    const { isMobile } = useIsMobile();
    const gridItems = useGridDataGenerator(posts, imageGridItems, isMobile);
    const app = ref<HTMLDivElement | null>(null);
    const { height } = useHeight(app);

    const onInputSubmit = (content: string) => {
      addPost({ content });
      showSuccessToast();
    };

    return { onInputSubmit, gridItems, app, height };
  },
});
</script>

<style lang="scss" scoped>
.app {
  margin: 1.55rem 1.55rem 0 1.55rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 700px) {
    margin-right: 3rem;
  }

  .grid {
    flex: 1;
  }

  .footer {
    margin-top: 3.55rem;
  }
}

@media screen and (max-width: 699px) {
  .side-info {
    display: none;
  }
}
</style>

<style lang="scss">
@font-face {
  font-family: "Jessi Neue";
  src: url("/fonts/JessiNeue-Light.eot");
  src: url("/fonts/JessiNeue-Light.eot?#iefix") format("embedded-opentype"),
    url("/fonts/JessiNeue-Light.woff2") format("woff2"),
    url("/fonts/JessiNeue-Light.woff") format("woff"),
    url("/fonts/JessiNeue-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body,
input,
button {
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

input,
button {
  &:focus {
    outline: 1px black solid;
  }
}

#app {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
