
import { defineComponent, ref, SetupContext } from "vue";

const maxLength = 90;

export default defineComponent({
  setup(props, { emit }: SetupContext) {
    const text = ref("");
    const onSubmit = () => {
      if (!text.value) {
        return;
      }

      emit("toe-submit", text.value.slice(0, maxLength));
      text.value = "";
    };

    return { text, onSubmit, maxLength };
  },
});
