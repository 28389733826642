<template>
  <div class="wrapper">
    <img :src="src" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    src: String,
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 1rem;

  img {
    opacity: 0.05;
    transition: opacity 250ms ease-in-out;

    &:hover {
      opacity: 1;
      transition: opacity 500ms ease-in-out;
    }
  }
}
</style>
