import { getApp, initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

export const initAnalytics = (
  firebaseConfig: Parameters<typeof initializeApp>[0]
) => {
  try {
    getApp();
  } catch (e) {
    initializeApp(firebaseConfig);
  }

  const analytics = getAnalytics();

  return { logEvent: (eventName: string) => logEvent(analytics, eventName) };
};
