import { computed, Ref } from "vue";
import {
  EmptyGridItem,
  GridItem,
  GridItemSize,
  GridItemType,
  ImgGridItem,
  TextGridItem,
} from "@toe/ui";
import { Post } from "@toe/post";
import { UnwrapNestedRefs } from "@vue/reactivity";

const convertPostToTextGridItem = ({ id, content }: Post): TextGridItem => ({
  type: GridItemType.Text,
  size: GridItemSize.Size1x1,
  id,
  content,
});

const createEmptyGridItem = (id: string): EmptyGridItem => ({
  type: GridItemType.Empty,
  size: GridItemSize.Size1x1,
  id,
});

const generateEmptyGridItems = (amount: number): EmptyGridItem[] =>
  Array.from(Array(amount)).map((_, index) =>
    createEmptyGridItem(`empty-${index}`)
  );

const passAll = () => true;
const filterOutEmptyItems = ({ type }: GridItem) => type !== GridItemType.Empty;

export const useGridDataGenerator = (
  posts: UnwrapNestedRefs<Record<string, Post>>,
  imageGridItems: ImgGridItem[],
  isMobile: Ref<boolean>
) =>
  computed((): GridItem[] => {
    const postsArray = Object.values<Post>(posts);

    return [
      ...postsArray.map(convertPostToTextGridItem),
      ...generateEmptyGridItems(postsArray.length * 3),
      ...imageGridItems,
    ]
      .filter(isMobile.value ? filterOutEmptyItems : passAll)
      .sort(() => 0.5 - Math.random());
  });
