<template>
  <div class="input">
    <form @submit.prevent="onSubmit">
      <div class="input-wrapper">
        <input
          :maxlength="maxLength"
          class="input"
          type="text"
          v-model.trim="text"
          autofocus
        />
        <transition name="fade">
          <span class="counter" v-show="text.length">
            {{ text.length }} / {{ maxLength }}</span
          >
        </transition>
      </div>

      <button type="submit">tak było, pamiętam</button>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, SetupContext } from "vue";

const maxLength = 90;

export default defineComponent({
  setup(props, { emit }: SetupContext) {
    const text = ref("");
    const onSubmit = () => {
      if (!text.value) {
        return;
      }

      emit("toe-submit", text.value.slice(0, maxLength));
      text.value = "";
    };

    return { text, onSubmit, maxLength };
  },
});
</script>

<style scoped lang="scss">
form {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    width: 582px;
    flex-direction: row;
  }
}

input {
  font-size: 1rem;
  padding: 0.65rem;
  background: linear-gradient(to right, #f9f7f7, white) #f9f7f7;
  border: 0;
  width: 100%;
}

button {
  background: #cfcfcf;
  font-size: 0.95rem;
  color: white;
  border: 0;
  padding: 0.65rem;
  cursor: pointer;
  margin-top: 2rem;

  @media screen and (min-width: 900px) {
    margin-left: 1rem;
    margin-top: 0;
  }
}

.input-wrapper {
  position: relative;
  display: flex;
  flex: 1;

  .counter {
    position: absolute;
    font-size: 0.85rem;
    top: 100%;
    margin-top: 0.55rem;
    right: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
